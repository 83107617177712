<template>
    <div class="about-content">
        <v-container id="intro" class="cyan text-center white--text mb-4" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="8">
                    <h2 class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        Nachhaltigkeit
                    </h2>
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">Webseiten mit Verantwortung</h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-card class="h-100" color="orange" elevation="0" tile>
                        <v-fade-transition>
                            <v-img
                            alt="Portrait Sara"
                            :src="require('@/assets/images/team/' + selectedPortrait)"
                            height="100%"
                            ></v-img>
                        </v-fade-transition>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-card class="patterned d-flex align-center h-100" color="orange" elevation="0" tile>
                        <v-responsive class="align-center">
                            <v-card-text class="white--text text-large pa-10">
                                <blockquote class="headline text-center mb-6">
                                    &raquo;Nachhaltigkeit bedeutet für mich mehr als nur klimafreundliches Hosting. Ich verstehe sie ganzheitlich – ökologisch, sozial und wirtschaftlich.
                                     Als Webdesignerin trage ich Verantwortung für die digitalen Produkte, die ich erstelle.
                                      Deshalb setze ich auf umweltfreundliche Technologie, faire Zusammenarbeit und ethisches Wirtschaften.&laquo;
                                </blockquote>
                                <p class="text-center">Sara Geller – Die Webmacherin</p>
                                <!--<p>
                                    Auch Webseiten können nachhaltig sein. Stabil, zeitlos, wartbar, anpassbar, erweiterbar, datensparsam, zugänglich, leicht zu bedienen und selbstverständlich grün gehostet (in der EU).
                                     Durch die Verwendung von Open Source Software und einer ausführlichen Einschulung in die Bedienung ihrer Webseite sind meine Kund:innen unabhängig und flexibel wenn es um ihre Webseite geht.
                                </p>
                                <p>
                                    Um meine Bemühungen um ökologische und soziale Nachhaltigkeit in meinem Unternehmen transparent zu machen,
                                     habe ich einen Fokusbericht nach den Kriterien der Gemeinwohl-Ökonomie (GWÖ bzw. Econgood) erstellt.
                                      Dieser basiert auf der Gemeinwohl-Matrix die die Auswirkungen meines unternehmerischen Handelns auf unterschiedliche Berührungsgruppen
                                       (wie Kunden, Lieferanten, die Gesellschaft) anhand verschiedener Kriterien aufschlüsselt.
                                </p>-->
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="patterned d-flex h-100" color="indigo darken-1" elevation="0" tile>
                        <v-responsive>
                            <v-card-text class="white--text text-center pa-10">
                                <h2 class="display-1">
                                    Wie wird Webdesign nachhaltig?
                                </h2>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" order-md="2">
                    <v-card class="d-flex flex-column h-100" color="indigo darken-1" elevation="0" tile>
                        <v-img
                        alt="Lünersee umrahmt von Bergwiesen - Fotograf: Andreas Weilguny"
                        role="presentation"
                        :aspect-ratio="$vuetify.breakpoint.sm ? 2/1 : 1.5/1"
                        :src="require('@/assets/images/mood/andreas-weilguny-kFCRgRhmOu4-unsplash.jpg')"
                        height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" order-md="1">
                    <v-card class="d-flex flex-column justify-center h-100" color="indigo darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h2 class="mb-5">Ökologische Nachhaltigkeit</h2>
                            <h4>Grüne Technologie für eine digitale Zukunft</h4>
                            <p>
                                Das Internet verbraucht weltweit mehr Energie als die Luftfahrtindustrie. Deshalb optimiere ich jede Webseite, um ihren Ressourcenverbrauch zu minimieren:
                            </p>
                            <ul>
                                <li>
                                    <strong>Grünes Hosting</strong> – Alle Webseiten laufen auf Servern in der EU, die mit Ökostrom betrieben werden.
                                </li>
                                <li>
                                    <strong>Effiziente Webseiten</strong> – Ich vermeide unnötige Datenlast und optimiere die Performance für einen geringeren CO₂-Fußabdruck.
                                </li>
                                <li>
                                    <strong>Nachhaltige Hardware</strong> – Mein Arbeitsmaterial kaufe ich gebraucht und verwende es so lange wie möglich. Denn auch ich muss nachhaltig agieren, damit meine Dienstleistung nachhaltig sein kann.
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" order-md="3">
                    <v-card class="d-flex flex-column h-100" color="indigo darken-1" elevation="0" tile>
                        <v-img
                        alt="Hände auf einem Baumstamm - Fotograf: Shane Rounce"
                        role="presentation"
                        :aspect-ratio="$vuetify.breakpoint.sm ? 2/1 : 1.5/1"
                        :src="require('@/assets/images/mood/shane-rounce-DNkoNXQti3c-unsplash.jpg')"
                        height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" order-md="4">
                    <v-card class="d-flex flex-column justify-center h-100" color="indigo darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h2 class="mb-5">Soziale Nachhaltigkeit</h2>
                            <h4>Faires Webdesign für alle</h4>
                            <p>
                                Nachhaltigkeit ist auch eine soziale Frage. Ich arbeite fair, transparent und unterstütze meine Kund:innen und Partner:innen auf Augenhöhe:
                            </p>
                            <ul>
                                <li>
                                    <strong>Barrierefreiheit (Accessibility)</strong> – Webseiten, die für alle nutzbar sind, unabhängig von Alter oder Beeinträchtigungen.
                                </li>
                                <li>
                                    <strong>Transparente Preise</strong> – Offenlegung von Stundensätzen und realistische Angebote ohne versteckte Kosten.
                                </li>
                                <li>
                                    <strong>Rabatte für gemeinnützige Organisationen</strong> – Vereine, Künstler und soziale Einrichtungen erhalten vergünstigte Konditionen.
                                </li>
                                <li>
                                    <strong>Bildung & Wissen teilen</strong> – Als Trainerin am WIFI vermittle ich Fachwissen, um nachhaltige Webentwicklung weiterzugeben.
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" order-md="6">
                    <v-card class="d-flex flex-column h-100" color="indigo darken-1" elevation="0" tile>
                        <v-img
                        alt="Windrad und Schafe auf einer Wiese - Fotograf: Annie Spratt"
                        role="presentation"
                        :aspect-ratio="$vuetify.breakpoint.sm ? 2/1 : 1.5/1"
                        :src="require('@/assets/images/mood/annie-spratt-Iqwnr00mfQM-unsplash.jpg')"
                        height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" order-md="5">
                    <v-card class="d-flex flex-column justify-center h-100" color="indigo darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h2 class="mb-5">Wirtschaftliche Nachhaltigkeit</h2>
                            <h4>Langfristig denken, nachhaltig wirtschaften</h4>
                            <p>
                                Ich verstehe Unternehmertum als Verantwortung für die Gesellschaft. Deshalb setze ich auf langfristige, faire Geschäftsbeziehungen und nachhaltige Investitionen:
                            </p>
                            <ul>
                                <li>
                                    <strong>Unterstützung lokaler Anbieter</strong> – Wo möglich, arbeite ich mit Unternehmen aus Vorarlberg oder der EU zusammen.
                                </li>
                                <li>
                                    <strong>Open Source statt Lizenzkosten</strong> – Ich setze auf frei verfügbare Software wie WordPress & WooCommerce, um unnötige Abhängigkeiten von Großkonzernen zu vermeiden.
                                </li>
                                <li>
                                    <strong>Spenden & Engagement</strong> – Ein Teil meines Gewinns geht an Umwelt- und Tierschutzorganisationen.
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="patterned d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-responsive>
                            <v-card-text class="white--text text-center pa-10">
                                <h2 class="display-1">
                                    Nachhaltigkeit muss transparent und konkret sein
                                </h2>
                                <p class="text-xlarge">
                                    Deshalb orientiere ich mich an den Prinzipien der Gemeinwohl-Ökonomie (GWÖ) und dokumentiere mein nachhaltiges Handeln in einem Gemeinwohl-Fokusbericht.
                                     Dieser Bericht zeigt detailliert, wie ich ökologische, soziale und ethische Verantwortung in meinem Unternehmen umsetze – von der Auswahl meiner Lieferanten bis hin zur fairen Preisgestaltung.
                                      Transparenz ist der erste Schritt zu echter Nachhaltigkeit.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="d-flex flex-column h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h2 class="mb-5">Mein Engagement bei der Gemeinwohl-Ökonomie (GWÖ)</h2>
                            <h4>Warum GWÖ?</h4>
                            <p>
                                Die Gemeinwohl-Ökonomie (GWÖ) bietet eine Alternative zum rein profitorientierten Wirtschaften.
                                 Sie bewertet Unternehmen nicht nur nach finanziellen Kennzahlen, sondern auch nach ihrem Beitrag zum Wohl von Mensch und Umwelt.
                            </p>
                            <p>
                                Die GWÖ hilft mir, mein Unternehmen konsequent nachhaltig auszurichten – in Bezug auf Kund:innen, Partner:innen, Lieferant:innen und die Gesellschaft.
                            </p>
                            <p>Mehr über die Gemeinwohl-Ökonomie erfährst du hier:</p>
                        </v-card-text>
                        <div class=" pa-10">
                            <v-btn
                                block
                                class="mb-5"
                                color="secondary"
                                href="https://austria.econgood.org/vorarlberg/"
                                large
                                target="blank"
                            >
                                Mehr Infos zur GWÖ Vorarlberg
                                <v-icon dark right>mdi-launch</v-icon>
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="d-flex flex-column h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h2 class="mb-5">Mein Gemeinwohl-Fokusbericht</h2>
                            <p>
                                Mein Unternehmen wurde nach den GWÖ-Kriterien analysiert. Nachfolgend findest du den gesamten Bericht aus dem Jahr 20424 und meine Lieferantenliste für das Jahr 2023.                            </p>
                        </v-card-text>
                        <div class=" pa-10">
                            <v-btn
                                block
                                class="mb-5"
                                color="primary"
                                :href="require('@/assets/Gemeinwohl-Fokusbericht - Die Webmacherin.pdf')"
                                large
                                target="blank"
                            >
                                <v-icon dark left>mdi-file</v-icon>
                                Gemeinwohl-Fokusbericht 2024
                            </v-btn>
                            <v-btn
                                block
                                color="primary"
                                :href="require('@/assets/Webmacherin-Lieferanten-2023.pdf')"
                                large
                                target="blank"
                            >
                                <v-icon dark left>mdi-file</v-icon>
                                Lieferantenliste 2023
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>

export default {
    name: "Sustainability",
    methods: {
        randomPortrait (items) {
            return items[Math.floor(Math.random()*items.length)];
        }
    },
    created() {
        this.selectedPortrait = this.randomPortrait(this.portraits);

        /*let self = this
        setInterval(() =>{ 
            self.selectedPortrait = self.randomPortrait(self.portraits);
        }, 1000);*/
    },
    data: () => ({
        portraits:   [
            /*'portrait-sara-katze.webp',
            'portrait-sara-laptop.jpg',
            'portrait-sara-neutral.jpg',*/
            'portrait-sara-lachend.webp',
        ],
        selectedPortrait: null,
    })
};
</script>
